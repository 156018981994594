import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash.get';
import { parseISO, format } from 'date-fns';

import Layout from '../components/Layout';
import HeroSection from '../components/HeroSection';
import PostSection from '../components/PostSection';
import CtaSection from '../components/CtaSection';
import NewsletterSection from '../components/NewsletterSection';
import TestimonialsSection from '../components/TestimonialsSection';
import SEO from '../components/SEO';
import linkResolver from '../util/prismicLinkResolver';

import styles from '../styles/global.scss';

export const avatar64Image = graphql`
  fragment avatar64Image on File {
    childImageSharp {
      fixed(fit: COVER, height: 64, width: 64) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;

/**
 * Save the avatars in the folder ./src/images/testimonials and
 * update the paths (relative to ./src/images/) in this section.
 * Then update the name/quote/company down below.
 */
export const query = graphql`
  query {
    avatar1: file(relativePath: { eq: "testimonials/sk.jpeg" }) {
      ...avatar64Image
    }
    avatar2: file(relativePath: { eq: "testimonials/sm.jpeg" }) {
      ...avatar64Image
    }
    avatar3: file(relativePath: { eq: "testimonials/be.jpeg" }) {
      ...avatar64Image
    }
    prismic {
      posts: allBlog_posts(sortBy: date_DESC, first: 1) {
        edges {
          node {
            title
            extract
            date
            _meta {
              id
              uid
              type
            }
            cover_image
            cover_imageSharp {
              childImageSharp {
                fluid(maxWidth: 560, maxHeight: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    scape: file(relativePath: { eq: "cityscape.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />

      <HeroSection
        color="primary"
        size="huge"
        backgroundImage={<Img fluid={data.scape.childImageSharp.fluid} />}
        backgroundImageOpacity={0.3}
        backgroundImageColor={styles.primaryColor}
        title="Your full-service commercial brokerage"
        actionText={['See Our Listings', 'Contact Us']}
        actionURL={['/listings', '/contact-us']}
        actionType={['outlined', '']}
      />
      {data.prismic.posts.edges.map(({ node }, idx) => (
        <PostSection
          key={idx}
          color="white"
          size="medium"
          reversed={true}
          backgroundImageSrc=""
          backgroundImageOpacity={1}
          title={get(node, 'title.0.text', '---')}
          subtitle={get(node, 'extract.0.text', '---')}
          pretitle={format(parseISO(node.date), 'MMMM d')}
          actionText="Read More"
          actionType="outlined"
          actionURL={linkResolver(node._meta)}
          image={
            <Img
              fluid={node.cover_imageSharp.childImageSharp.fluid}
              alt={node.cover_image.alt}
            />
          }
          data-aos="fade-up"
        />
      ))}
      <CtaSection
        color="primary"
        size="bold"
        title="Ready to get started?"
        actionText="Contact Us"
        actionURL="/contact-us"
      />
{/*      <TestimonialsSection
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
        items={[
          {
            avatar: (
              <Img
                fixed={data.avatar1.childImageSharp.fixed}
                alt="Sarah Kline"
                className="is-rounded"
              />
            ),
            name: 'Sarah Kline',
            quote:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
            company: 'Company',
          },
          {
            avatar: (
              <Img
                fixed={data.avatar2.childImageSharp.fixed}
                alt="Shawna Murray"
                className="is-rounded"
              />
            ),
            name: 'Shawna Murray',
            quote:
              'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam!',
            company: 'Company',
          },
          {
            avatar: (
              <Img
                fixed={data.avatar3.childImageSharp.fixed}
                alt="Blake Elder"
                className="is-rounded"
              />
            ),
            name: 'Blake Elder',
            quote:
              'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae.',
            company: 'Company',
          },
        ]}
      />*/}
      <NewsletterSection
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and news"
        buttonText="Subscribe"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
    </Layout>
  );
};

export default IndexPage;
