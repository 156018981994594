export const checkStatus = response => {
  if (response.ok) return response;
  throw Error(response.statusText);
};

export const returnBody = async response => {
  let body;
  const responseCopy = response.clone();
  try {
    body = await response.json();
  } catch (error) {
    body = await responseCopy.text();
  }
  return body;
};
