import { checkStatus, returnBody } from './fetch-utils';

function subscribe(data) {
  return fetch('/api/newsletter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then(returnBody);
}

export default { subscribe };
