import React from 'react';
import Section from './Section';
import SectionHeader from './SectionHeader';
import SectionButton from './SectionButton';
import SectionAction from './SectionAction';

function CtaSection(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageSrc={props.backgroundImageSrc}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container has-text-centered">
        <div className="columns is-vcentered is-centered is-variable is-desktop-8 is-multiline">
          <div className="column is-narrow" data-aos="fade-right">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={2}
              spaced={false}
              className="has-text-centered"
            />
          </div>
          <div className="column is-narrow" data-aos="fade-left">
            {props.actionText && (
              <SectionAction
                parentColor={props.color}
                size="medium"
                to={props.actionURL}
              >
                {props.actionText}
              </SectionAction>
            )}
            {props.buttonText && (
              <SectionButton
                parentColor={props.color}
                size="medium"
                onClick={props.buttonOnClick}
              >
                {props.buttonText}
              </SectionButton>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default CtaSection;
